export const bands = [
  {
    name: 'Dinner for Wolves',
    link: 'https://dinnerforwolves.bandcamp.com/'
  },
  {
    name: 'LSD&D',
    link: 'https://www.reverbnation.com/lsdnd'
  },
  {
    name: 'Chariots of Rubber',
    link: 'https://www.youtube.com/watch?v=MlrjQHtJ-98'
  },
  {
    name: 'Diamond Tuck and the Privates',
    link: 'https://myspace.com/highrocks'
  }
]
